<template>
  <el-skeleton v-if="fetching" animated>
    <template slot="template">
      <el-skeleton-item variant="image" style="width: 100%;height: 100%" />
    </template>
  </el-skeleton>
  <img v-else v-show="source" :src="source" @click="showImage" :alt="alt" ref="img_element" style="cursor: pointer">
</template>

<script>
import UTIF from '@/utils/UTIF'
export default {
  props:{
    src:{
      type: String,
      default: ''
    },
    alt:{
      type: String,
      default: 'Image'
    }
  },
  data(){
    return {
      source: null,
      fetching: true
    }
  },
  name: 'TifCheckImage',
  created() {
    if (this.src) this.setImage()
  },
  methods:{
    showImage(){
      window.open('').document.write(this.$refs.img_element.outerHTML);
    },
    setImage(src) {
      this.fetching = true
      let xhr = new XMLHttpRequest();
      xhr.open('GET', src ?? this.src);
      xhr.responseType = 'arraybuffer';
      xhr.onload = (e) => {
        this.source = UTIF.bufferToURI(e.target.response)
        this.fetching = false
      };
      xhr.send();
    },
  },
}
</script>

<style >

</style>
<template>
  <div
    class="
      widgetContainer
      bg-white
      checkDepositSuccess
      appStatus appStatus--approved
    ">
    <div class="navigationHeader drawer-header-default">
      <div class="navigationHeader__inner">
        <p class="title">
          {{ $t('RCD_captureScreen_navTitle') }}
        </p>
        <p class="cancel" @click="done">
          <i class="el-icon-close" />
        </p>
      </div>
    </div>
    <div class="widgetContainer__body">
      <p class="title" />
      <p class="description">
        {{ $t('RCD_successDesc') }}
      </p>

      <ul class="checkDepositSuccess__details">
        <li>
          <span class="label">{{ $t('pay_row_amount') }}:</span>
          <div>
            <p>{{ getSuccessDepositData.amount | currency }}</p>
          </div>
        </li>
        <li>
          <span class="label">{{ $t('RCD_deposit_to') }}:</span>
          <div>
            <p v-if="isBusinessAccountType">
              {{ getSelectedBusiness.legalName }}
            </p>
            <span>{{ getSelectedAccount.label }}</span>
          </div>
        </li>
      </ul>
      <p class="description">
        Kindly check the processed images before leaving you may re-upload using the re-upload button.
      </p>
      <div class="preview-details uploadCheckImage">
        <div class="uploadCheckImage__header checkDeposit">
          <p>
            {{ $t('RCD_frontTitle') }}
          </p>
          <span @click="toggleUpload('front')">
            {{ !uploadToggle.front ? $t('RCD_reuploadButton') : 'Cancel' }}
          </span>
        </div>
        <div v-if="!uploadToggle.front" class="uploadCheckImage__image">
          <TifCheckImage :key="1" ref="tifCheckImage_front" v-if="processedImages.front" :src="processedImages.front.processed" />
          <span class="description" v-else>Please Wait</span>
        </div>
        <template v-else>
          <FileSelect @fileChange="fileOnChange($event,'front')" upload-btn-text="Select Image" reselect-btn-text="Choose">
            <ul>
              <li>{{ $t('RCD_captureInstruction1') }}</li>
              <li>{{ $t('RCD_captureInstruction2') }}</li>
              <li>{{ $t('RCD_captureInstruction3') }}</li>
            </ul>
          </FileSelect>
          <el-button :disabled="!toUpload.front" type="primary" class="width-100 el-button__brand brand" @click="upload('front')">
            Upload
          </el-button>
        </template>
      </div>

      <div class="preview-details uploadCheckImage">
        <div class="uploadCheckImage__header checkDeposit">
          <p>
            {{ $t('RCD_backTitle') }}
          </p>
          <span @click="toggleUpload('back')">
            {{ !uploadToggle.back ? $t('RCD_reuploadButton') : 'Cancel' }}
          </span>
        </div>
        <div v-if="!uploadToggle.back" class="uploadCheckImage__image">
          <TifCheckImage :key="2" ref="tifCheckImage_back" v-if="processedImages.back" :src="processedImages.back.processed" />
          <span class="description" v-else>Please Wait</span>
        </div>
        <template v-else>
          <FileSelect @fileChange="fileOnChange($event,'back')" upload-btn-text="Select Image" reselect-btn-text="Choose">
            <ul>
              <li>{{ $t('RCD_captureInstruction1') }}</li>
              <li>{{ $t('RCD_captureInstruction2') }}</li>
              <li>{{ $t('RCD_captureInstruction3') }}</li>
            </ul>
          </FileSelect>
          <el-button :disabled="!toUpload.back" type="primary" class="width-100 el-button__brand brand" @click="upload('back')">
            Upload
          </el-button>
        </template>
      </div>
    </div>
    <div class="el-drawer__footer">
      <el-button
        type="primary"
        class="welcome__get-started el-button__brand brand width-100"
        @click="done">
        {{ $t('done') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import {receiveService} from '@/services/solidfi';
import TifCheckImage from '@m/check-deposit/views/TifCheckImage';
import FileSelect from '@m/check-deposit/components/FileSelect';
export default {
  components: {
    FileSelect,
    TifCheckImage
  },
  computed: {
    ...mapGetters('kyb', ['getSelectedBusiness']),
    ...mapGetters('account', ['getSelectedAccount', 'isBusinessAccountType']),
    ...mapGetters('checkDeposit', [
      'getSuccessDepositData',
      'getSelectedContact'
    ])
  },
  data() {
    return {
      processedImages:{},
      interval: null,
      uploadToggle:{
        front: false,
        back: false,
      },
      toUpload:{
        front: null,
        back: null,
      }
    };
  },
  beforeDestroy() {
    this.$store.commit('checkDeposit/setSuccessDepositData',null)
  },
  async created() {
    await this.getProcessedImages()
  },
  methods: {
    ...mapMutations('checkDeposit', ['setSelectedContact']),
    done() {
      this.drawerClose();
      this.setSelectedContact(null);
      this.goToDashboard();
    },
    toggleUpload(from){
      this.uploadToggle[from] = !this.uploadToggle[from];
      this.toUpload[from] = null;
    },
    async getProcessedImages(){
      this.interval = setInterval(async () => {
        const result = await receiveService.getUploadCheckFiles(this.getSuccessDepositData.id);
        if (result.data.front.processed.length && result.data.back.processed.length){
          clearInterval(this.interval)
          this.processedImages = result.data
        }
      },3000)
    },
    fileOnChange(e,from){
      this.toUpload[from] = e
    },
    upload(from){
      const loader = this.showLoader()
      const formData = new FormData();
      formData.append(from, this.toUpload[from]);
      formData.append('accountId', this.getSelectedAccount.id);
      receiveService.uploadCheckFiles({
        id: this.getSuccessDepositData.id,
        data: formData
      })
        .then(async () => {
          this.processedImages[from].processed = null
          this.toUpload[from] = null;
          this.uploadToggle[from] = false;
          this.interval = setInterval(async () => {
            const result = await receiveService.getUploadCheckFiles(this.getSuccessDepositData.id);
            if (!this.isSameImage(this.processedImages[from].original,result.data[from].original)){
              this.processedImages[from] = result.data[from]
              this.$refs[`tifCheckImage_${from}`].setImage(result.data[from].processed)
              clearInterval(this.interval)
            }
          },3000)
        })
        .catch((e) => {
          console.log(e);
          this.apiErrorMessage(e);
        })
        .finally(() => {
          loader.close();
        });
    },
    getImageDataUrl(img) {
      let canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      let ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      return canvas.toDataURL();
    },
    isSameImage(oldSrc,newSrc){
      let oldImg = new Image;
      oldImg.src = oldSrc
      oldImg.crossOrigin = 'anonymous'
      let newImg = new Image;
      newImg.src = newSrc
      newImg.crossOrigin = 'anonymous'
      console.log('Same: ',this.getImageDataUrl(oldImg) === this.getImageDataUrl(newImg))
      return this.getImageDataUrl(oldImg) === this.getImageDataUrl(newImg)
    }
  }
};
</script>

<style lang="scss">
.checkDepositSuccess {

  .description {
    padding-bottom: 32px;
    white-space: pre-line;
    padding: 16px 10px 32px;
  }

  &__details {
    border: 1px solid #edeff1;
    border-radius: var(--radius);
    padding: 24px 20px;
    display: flex;
    flex-direction: column;

    li {
      display: flex;
      padding-bottom: 18px;
      text-align: left;
      font-size: 14px;
      line-height: 18px;

      .label {
        min-width: 140px;
        color: var(--grey-1);
      }

      div {
        span {
          color: var(--grey-1);
        }
      }
    }
  }
  .widgetContainer__body{
    justify-content: normal;

    .hidden-file {
      display: none;
    }
    .el-checkbox__input.is-disabled .el-checkbox__inner {
      background-color: #fff;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: var(--branding-primary);
      border-color: var(--branding-primary);
    }
    .el-checkbox__input.is-disabled .el-checkbox__inner::after {
      border-color: #ffffff;
    }
    .uploadCheckImage{
      .uploadCheckImage__image{
        height: auto;
      }
      ul {
        padding-bottom: 16px;
        &{
          text-align: left;
        }
        li {
          color: #90909e;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}

</style>
